function addEventListenerForPaymentMethod() {
  $("#make-a-payment .payment-method-radios input").bind(
    "click",
    function (e) {
      var officeForm = this.closest(".office-form");
      var formCards = officeForm.getElementsByClassName("form-card");
      var value = this.getAttribute("value");

      $("input[name=PaymentMethodRadio]").removeAttr("checked");
      $(`input[name=PaymentMethodRadio][value=${value}]`).attr(
        "checked",
        "checked"
      );

      for (var i = 0; i < formCards.length; i++) {
        if (formCards[i].getAttribute("data-method") == value) {
          
          if (formCards[i].classList.contains("selected") == false) {
            for (var j = 0; j < formCards.length; j++) {
              $(formCards[j]).slideUp(500);
              formCards[j].classList.remove("selected");
            }
            formCards[i].classList.add("selected");
            $(formCards[i]).slideDown(500);
          }
        }
      }
    }
  );

  $("#cards-selection, #cards-selection-prepay").bind('change', function() {
    var formCard = this.closest(".form-card");

    var cardTypes = formCard.getElementsByClassName("card-type");
    var wCards = formCard.getElementsByClassName("w-card");
    var wCardsPrepay = formCard.getElementsByClassName("w-card-prepay");

    var value = $(this).val();

    if (this.classList.contains("selected") == false) {
      for (var i = 0; i < cardTypes.length; i++) {
        if (cardTypes[i].getAttribute("data-card") == value) {
          cardTypes[i].classList.add("active");
          $(cardTypes[i]).slideDown(500);
        } else {
          cardTypes[i].classList.remove("active");
          $(cardTypes[i]).slideUp(500);
        }
      }

      for (var i = 0; i < wCards.length; i++) {
        if (wCards[i].getAttribute("data-index") == value) {
          wCards[i].classList.add("selected");
        } else {
          wCards[i].classList.remove("selected");
        }
      }

      for (var i = 0; i < wCardsPrepay.length; i++) {
        if (wCardsPrepay[i].getAttribute("data-index") == value) {
          wCardsPrepay[i].classList.add("selected");
        } else {
          wCardsPrepay[i].classList.remove("selected");
        }
      }

    }
  });

  let _inputs = $(".payment-method-radios input[type=radio]");
  if (_inputs.length > 0) {
    $(_inputs[0]).click();
  }
}
